import { Component, OnInit } from '@angular/core';
import { CARS } from 'src/app/cars';
import { SITE_SETTINGS } from 'src/app/site-settings';

@Component({
  selector: 'app-tariff',
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.component.css']
})
export class TariffComponent implements OnInit {
  settings = SITE_SETTINGS;
  cars = CARS;
  constructor() { }

  ngOnInit(): void {
  }

}
