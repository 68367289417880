<!-- FOOTER START -->
<footer class="wo-footer-wrap">
    <img src="assets/images/footer/shape.png" class="wo-footershape" alt="img description">
    <div class="wo-footer">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="wo-fpostwrap">
              <div class="wo-fpostwrap__title">
                <h4>Planning for outstation trips?</h4>
                <h4>Its Easy Just <span>Search, Book & Relax</span></h4>
              </div>
              <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="wo-fpost">
                    <img src="assets/images/footer/img-03.jpg" alt="img description">
                    <h4>Best Price Guaranteed</h4>
                    <span>Best Price Guarantee is a no-questions-asked price assurance by {{settings.siteName}} for all
                      your
                      outstation cab bookings. </span>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="wo-fpost">
                    <img src="assets/images/footer/img-02.jpg" alt="img description">
                    <h4>Quality of Vehicles</h4>
                    <span>We have sanitized cars with healthy drivers who does regular basic health checks to ensure the
                      safety of our customers.
  
                    </span>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="wo-fpost">
                    <img src="assets/images/footer/img-01.jpg" alt="img description">
                    <h4>Transprent Billing</h4>
                    <span> When you book a ride with us, we'll present you with an all-inclusive quote, which includes
                      tolls and taxes. The price you see is the price you pay.</span>
                  </div>
                </div>
              </div>
            </div>
  
          </div>
          <div class="wo-fthree-columns">
            <div class="col-12 col-lg-6">
              <strong class="wo-f-logo">
                <a href="index.html"><img src="assets/logo.jpg" alt="img description"></a>
              </strong>
              <div class="wo-fdescription">
                <p>We are one of the best taxi service providers in all major cities and towns throughout Tamilnadu, Karnataka, Kerala, Andhra & Telgana.</p>
              </div>
              <div class="wo-fshare">
                <a href="https://play.google.com/store/apps/details?id=com.kaviyadroptaxi.calvincare"><img src="assets/get-it-on-google-play-badge.png" alt="img description"></a>
                <br>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3">
              <div class="wo-fwidget">
                <div class="wo-fwidget__title">
                  <h5>Ourstation Cities</h5>
                </div>
                <ul class="wo-fwidget__content">
                  <li><a>Chennai</a></li>
                  <li><a>Coimbatore</a></li>
                  <li><a>Bangalore</a></li>
                  <li><a>Pondicherry</a></li>
                  <li><a>Vellore</a></li>
                  <li><a>Madurai</a></li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3">
              <div class="wo-fwidget">
                <div class="wo-fwidget__title">
                  <h5>Popular Routes</h5>
                </div>
                <ul class="wo-fwidget__content">
                  <li><a>
                      Chennai to Bangalore</a></li>
                  <li><a>
                      Bangalore to Pondicherry </a></li>
                  <li><a>Chennai to Coimbatore</a></li>
                  <li><a>
                      Madurai to Pondicherry</a></li>
                  <li><a>
                      Vellore to Bangalore</a></li>
                  <li><a>
                      Madurai to Salem </a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wo-footer-bottom">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="wo-footernav-content">
              <p class="wo-copyrights">Copyrights © 2021 by <a>{{settings.siteName}}</a> All
                Rights Reserved.</p>
              <nav class="wo-footernav">
                <ul>
                  <li><a href="https://www.calvincareinfotech.com">Developed By Calvin Care</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- FOOTER END -->