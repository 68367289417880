<div class="wo-aboutbannerwrap">
    <div class="wo-aboutbannerbg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 596.99">
            <path class="path1" d="M1923,0V456.68C963,456.68,963,597,3,597V0H1923Z" transform="translate(-3)"></path>
            <path class="path2" d="M1923,0V436.68C963,436.68,963,597,3,597V0H1923Z" transform="translate(-3)"></path>
        </svg>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="wo-aboutbanner">
                    <div class="wo-aboutbanner__title">
                        <h2><span>South India's Largest Intercity
                            </span><em> and Local Cab Services</em></h2>
                    </div>
                    <div class="wo-aboutbanner__description">
                        <p>
                            We are {{settings.siteName}} Car Rentals, an online cab booking aggregator, providing
                            customers with
                            reliable and premium Intercity and Local car rental services. Over the last decade, we are
                            uniquely placed as the largest chauffeur driven car rental company in India in terms of
                            geographical reach.
                        </p>
                        <br>
                        <p>
                            {{settings.siteName}}, the Brand of Outstation Cab Service acts as a cab booking facilitator
                            connecting
                            Cab operators and Riders across the country. Developed with a vision to use effective
                            technology to take care of your intercity travel needs at the lowest fares. We constantly
                            strive to achieve the benchmarks of cost effective, reliability and flexibility which makes
                            {{settings.siteName}} a trusted brand for all your Intercity travel needs.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<main class="wo-main">
    <!-- Why We’re So Different START -->
    <section class="wo-main-section wo-about-main-section">
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-lg-9 col-xl-8">
                    <div class="wo-sectionhead">
                        <div class="wo-sectionhead__title">
                            <span>People Always Wonder</span>
                            <h2>Why We’re<em> So Different</em></h2>
                        </div>
                        <div class="wo-sectionhead__description">
                            <p>Consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore etdolore magna
                                aliqua enim adinim ullamco laborisn nisi ut aliquip exea commodo eniam quis nostrud
                                exercitation consequat</p>
                        </div>
                    </div>
                </div>
                <div class="wo-differentpost-wrap">
                    <div class="col-12 col-md-6 col-lg-4">
                        <div class="wo-differentpost">
                            <img src="assets/taxi-stop.png" alt="img description">
                            <h3>Reliable transfers
                            </h3>
                            <p>Planning an outstation trip or looking for a ride to the airport? We got it all covered.
                                Make your trips hassle-free by pre-booking taxis from Taxida. When you plan your journey
                                with a reliable taxi service, what's there to worry about.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                        <div class="wo-differentpost">
                            <img src="assets/money.png" alt="img description">
                            <h3>Transparent pricing
                            </h3>
                            <p>Hate surprises? Fret not! Avoid all unforeseen costs with Taxida. When you book a ride
                                with us, we'll present you with an all-inclusive quote, which includes tolls and taxes.
                                The price you see is the price you pay.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                        <div class="wo-differentpost">
                            <img src="assets/rental-car.png" alt="img description">
                            <h3>Free cancellation up to 24 hrs
                            </h3>
                            <p>Unexpected things happen in life. Taxi cancellation has become much easier and quicker
                                with Taxida. If you want to cancel your ride, we offer free cancellation up to 24 hours
                                before the trip. The Policy extends to all rides. Happy riding!.
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>







</main>