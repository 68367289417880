<mat-horizontal-stepper linear #stepper>
  <mat-step>

    <form [formGroup]="airportFormGroup" (ngSubmit)="selectAirportCars()" class="wo-themeform wo-bannerform">
      <fieldset>
        <label class="wo-titleinput one">I’m Looking For:</label>

        <div class="wo-radioholder">
          <span class="wo-radio">
            <input type="radio" id="FromAirport" value="Cab From Airport" checked=""
              formControlName="trip">
            <label for="FromAirport">
              <span><i class="far fa-circle"></i>Cab From Airport</span>
            </label>
          </span>
          <span class="wo-radio">
            <input type="radio" id="ToAirport" value="Cab To Airport" formControlName="trip">
            <label for="ToAirport">
              <span><i class="far fa-circle"></i>Cab To Airport</span>
            </label>
          </span>
        </div>
        <div class="form-group">
          <label class="wo-titleinput">City</label>
          <div class="wo-form-icon">
            <input type="text" class="form-control" placeholder="Start typing city - e.g. Chennai"
              required="" formControlName="city" [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option}}
              </mat-option>
            </mat-autocomplete>

            <a class="wo-right-icon"><i class="ti-target"></i></a>
          </div>
          <div *ngIf="submitted && airportValidation.city.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="airportValidation.city?.errors.required">Airport city is required</div>
          </div>
        </div>
        <div class="form-group">
          <label *ngIf="airportValidation.trip.value === 'Cab From Airport'" class="wo-titleinput">
            Pickup Address
          </label>
          <label *ngIf="airportValidation.trip.value === 'Cab To Airport'" class="wo-titleinput">Drop
            Address</label>

          <div class="wo-form-icon">
            <input type="text" class="form-control" placeholder="Start typing city - e.g. Bangalore"
              required="" formControlName="address" #airportAddressRef>

            <a class="wo-right-icon"><i class="ti-target"></i></a>
          </div>
          <div *ngIf="submitted && airportValidation.address.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="airportValidation.address?.errors.required">Address is required</div>
          </div>
        </div>

        <div class="date-wrap">
          <div class="form-group">
            <label class="wo-titleinput">Pickup Date</label>
            <div class="wo-form-icon">
              <input [min]="minDate" type="text" class="form-control" placeholder="Select Date" required=""
                formControlName="date" (focus)="picker.open()" [matDatepicker]="picker">

              <mat-datepicker #picker></mat-datepicker>

              <a class="wo-right-icon"><i class="ti-calendar"></i></a>
            </div>
            <div *ngIf="submitted && airportValidation.date.errors" class="invalid-feedback"
              style="display: block;">
              <div *ngIf="airportValidation.date?.errors.required">Pickup date is required</div>
            </div>
          </div>
          <div class="form-group">
            <label class="wo-titleinput">Pickup Time</label>
            <div class="wo-form-icon">
              <input type="text" class="form-control" placeholder="Select Time" required=""
                formControlName="time" [ngxTimepicker]="time1">
              <ngx-material-timepicker #time1></ngx-material-timepicker>

              <a class="wo-right-icon"><i class="ti-timer"></i></a>
            </div>
            <div *ngIf="submitted && airportValidation.time.errors" class="invalid-feedback"
              style="display: block;">
              <div *ngIf="airportValidation.time?.errors.required">Pickup time is required</div>
            </div>
          </div>
        </div>

        <div class="form-group wo-form-btns">
          <!-- [disabled]="!outstationFormGroup.valid" -->
          <button type="submit" class="wo-btn">
            <mat-spinner *ngIf="showSpinner" [diameter]="40" [color]="123652"></mat-spinner>
            <span *ngIf="!showSpinner">Select Cars</span>
          </button>
        </div>
      </fieldset>
    </form>

    <div class="hide-map">
      <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="5">
        <ng-container *ngIf="showDirection">
          <agm-direction [origin]="airportValidation.city.value" [destination]="airportAddress"
            (onResponse)="onResponses($event)">
          </agm-direction>
        </ng-container>
      </agm-map>
    </div>

  </mat-step>
  <mat-step>
    <a (click)="back()"> <i class="fa fa-long-arrow-left fa-3" aria-hidden="true"></i>
      back
    </a>

    <div class="wo-tabtitle">
      <h4>Select your preffered vehicle</h4>
    </div>
    <div class="wo-vcomlistwrap">

      <ul class="wo-vcomlist">
        <li *ngFor="let car of estimations">
          <span>
            <img src="{{car.image}}" alt="" srcset=""> <br>
            {{car.carType}} <br>
            <em>({{car.passengers}} + 1)</em>
          </span>
          <span>
            {{car.ratePerKM}} ₹ / KM <br>
            <em>(Rate per km)</em>

          </span>
          <span class="inr">
            {{car.tripEstimation |currency:'INR'}} <br>
            <em>({{car.distance}} km)</em>
          </span>
          <div class="wo-header-btns-list">
            <a href="tel:{{settings.phoneNo}}" class="wo-btn"><i class="ti-mobile"></i>Click to call</a>
            <a (click)="selectedCar(car, 'airport')" class="wo-btn wp">
              Select</a>
          </div>
        </li>
      </ul>
    </div>

  </mat-step>
  <mat-step>
    <a (click)="back()"> <i class="fa fa-long-arrow-left fa-3" aria-hidden="true"></i>
      back
    </a>
    <div class="wo-tabtitle">
      <h4>Enter contact details</h4>
    </div>
    <form [formGroup]="contactFormGroup" (ngSubmit)="bookNowAirportTaxi()" class="wo-themeform wo-bannerform">
      <fieldset>

        <div class="form-group">
          <label class="wo-titleinput">Name</label>
          <div class="wo-form-icon">
            <input type="text" class="form-control" placeholder="Enter your name" required=""
              formControlName="name">
          </div>
          <div *ngIf="airportContactSubmitted && contactValidation.name.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="contactValidation.name?.errors.required">Name is required</div>
          </div>
        </div>
        <div class="form-group">
          <label class="wo-titleinput">Email</label>
          <div class="wo-form-icon">
            <input type="text" class="form-control" placeholder="Enter e-mail address" required=""
              formControlName="email">
          </div>
          <div *ngIf="airportContactSubmitted && contactValidation.email.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="contactValidation.email?.errors.required">Email is required</div>
            <div *ngIf="contactValidation.email?.errors.pattern">Enter valid email address</div>
          </div>
        </div>

        <div class="form-group">
          <label class="wo-titleinput">Mobile Number</label>
          <div class="wo-form-icon">
            <input type="text" class="form-control" placeholder="Enter contact number" required=""
              formControlName="phone">
          </div>
          <div *ngIf="airportContactSubmitted && contactValidation.phone.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="contactValidation.phone?.errors.required">Mobile number is required</div>
            <div *ngIf="contactValidation.phone?.errors.pattern">Enter valid mobile number</div>
          </div>
        </div>

        <div class="form-group wo-form-btns">
          <button type="submit" class="wo-btn">Book your ride</button>
        </div>
      </fieldset>
    </form>

    <mat-tab-group>
      <mat-tab label="Details">
        <div *ngIf="selectedCarType" class="col-12">
          <div class="x_car_book_left_siderbar_wrapper float_left">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <!-- Filter Results -->
                <div class="car-filter accordion x_inner_car_acc_accor">
                  <!-- Resources -->
                  <div class="x_car_access_filer_top_img">
                    <img src="{{selectedCarType.image}}" alt="car_img">
                    <h3>{{selectedCarType.carType}}</h3>
                    <p> Total distance {{selectedCarType.distance}} KM</p>
                  </div>
                  <hr>
                  <!-- Company -->
                  <div class="panel panel-default x_car_inner_acc_acordion_padding">
                    <div class="panel-heading">
                      <h5 class="panel-title"> <a data-toggle="collapse" href="#collapseTwo" class="collapse">
                          date</a> </h5>
                    </div>
                    <div id="collapseTwo" class="collapse show">
                      <div class="panel-body">
                        <div class="x_car_acc_filter_date">
                          <ul>
                            <li>From <span> {{selectedCarType.pickupDate}}</span>
                            </li>
                            <li>Time <span>{{selectedCarType.pickupTime}}</span>
                            </li>
                            <li>Duration <span>{{totalDuration}}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <!-- Attributes -->
                  <div class="panel panel-default x_car_inner_acc_acordion_padding">
                    <div class="panel-heading">
                      <h5 class="panel-title"> <a data-toggle="collapse" href="#collapseThree" class="collapse">
                          location</a> </h5>
                    </div>
                    <div id="collapseThree" class="collapse show">
                      <div class="panel-body">
                        <div class="x_car_acc_filter_date">
                          <ul>
                            <li>Airport City <span>{{selectedCarType.airportCity}}</span>
                            </li>
                            <li> {{selectedCarType.triptype === 'Cab From Airport' ? 'Pickup Address' : 'Drop Address'}} <span>{{airportAddress | limit : 20}}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div
                    class="panel panel-default x_car_inner_acc_acordion_padding x_car_inner_acc_acordion_padding_last">
                    <div class="panel-heading">
                      <h5 class="panel-title"> <a data-toggle="collapse" href="#collapsefour" class="collapse">
                          Fare Breakup</a> </h5>
                    </div>
                    <div id="collapsefour" class="collapse show">
                      <div class="panel-body">
                        <div class="x_car_acc_filter_date">
                          <ul>
                            <li>Base Fare <span>{{selectedCarType.baseFare}} ₹
                              </span>
                            </li>
                            <li>Driver Batta <span>{{selectedCarType.driverBatta}} ₹ </span>
                            </li>
                            <li>Rate per KM <span>{{selectedCarType.ratePerKM}} ₹</span>
                            </li>


                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="x_car_acc_filter_bottom_total">
                    <ul>
                      <li>total <span>{{selectedCarType.tripEstimation | currency: 'INR'}}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Inclusions">
        <ul *ngIf="selectedCarType" class="wo-vehicles__list">
          <li><span><i class="wo-themeicon"><img src="assets/money.png" alt="img description"></i>Base
              Fare for {{selectedCarType.distance}} km </span></li>
          <li><span><i class="wo-themeicon"><img src="assets/driver.png"
                  alt="img description"></i>Driver Allowance of {{selectedCarType.driverBatta |
              currency: 'INR'}}</span></li>
        
        </ul>
      </mat-tab>
      <mat-tab label="Exclusions">
        <ul *ngIf="selectedCarType" class="wo-vehicles__list">
          <li><span><i class="wo-themeicon"><img src="assets/images/index/featured/img-02.png"
                  alt="img description"></i>Pay {{selectedCarType.ratePerKM}} ₹ /km
              after {{selectedCarType.distance}} km</span></li>
          <!-- <li><span><i class="wo-themeicon"><img src="assets/night.png" alt="img description"></i>Night
              Driving Allowance (₹
              {{selectedCarType.driverBatta}})</span></li> -->
          <li><span><i class="wo-themeicon"><img src="assets/taxi.png"
                  alt="img description"></i>Multiple pickups/drops</span></li>
                  <li><span><i class="wo-themeicon"><img src="assets/taxes.png" alt="img description"></i>State
                    Tax & Toll </span></li>
      
          <li><span><i class="wo-themeicon"><img src="assets/toll.png" alt="img description"></i>Parking
              / Toll</span></li>
        </ul>
      </mat-tab>
    </mat-tab-group>

  </mat-step>

  <mat-step>
    <div *ngIf="bookingDetails" class="confirmationText">

      <div class="wo-sectionhead__title">
        <h2>Thank You, <em>{{bookingDetails.name}}</em> !</h2>
      </div>

      <p class="bookingId">Your Booking (ID: <span
          class="reservation-id">{{bookingDetails.bookingId}}</span>) is
        confirmed.</p>

      <p class="bookingMessage">The reservation details have been sent to your email
        ID and phone. You will receive your driver details
        2 hours prior to your pick up time. </p>

      <p class="bookingId" style="margin-top: 10px;">In these unprecedented times,
        {{settings.siteName}} is pleased to provide a comprehensive yet cost-effective rides. For
        details please contact <a href="tel:{{settings.phoneNo}}"> {{settings.phoneNo}} </a> </p>
    </div>
  </mat-step>
</mat-horizontal-stepper>