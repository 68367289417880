import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import * as moment from 'moment';
import { CARS } from 'src/app/cars';
import { PLACES, popular_cities } from 'src/app/places';
import { SITE_SETTINGS } from 'src/app/site-settings';
import { DateAdapter } from '@angular/material/core';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-outstation',
  templateUrl: './outstation.component.html',
  styleUrls: ['./outstation.component.css']
})
export class OutstationComponent implements OnInit {

  @ViewChild('origin', { read: ElementRef }) originElementRef: ElementRef;
  @ViewChild('destination', { read: ElementRef }) destinationElementRef: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;

  outstationFormGroup: FormGroup;
  contactFormGroup: FormGroup;
  airportFormGroup: FormGroup;
  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 5;
  cars = CARS;
  origin;
  destination;
  showDirection = false;
  pickupPlace;
  dropPlace;
  totalDistance;
  totalDuration;
  requestRide = false;
  rideDetails;
  name;
  phoneNo;
  places = PLACES;
  routeTotalDistance;
  routeTotalDuration;
  displayMaps = false;
  popularCities = popular_cities;
  placeIds = [];
  otherCity = false;
  settings = SITE_SETTINGS;
  estimations = [] as any;
  routeDetails;
  selectedCarType;
  originCityName;
  destinationCityName;
  bookingDetails;
  submitted = false;
  contactSubmitted = false;
  showSpinner = false;
  minDate = new Date();
  tomorrow = new Date();
  days;
  showDateError = false;

  constructor(private mapsAPILoader: MapsAPILoader,
    private location: LocationStrategy,
    private ngZone: NgZone, private http: HttpClient, private fb: FormBuilder, private ref: ChangeDetectorRef, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {
    this.tomorrow.setDate(new Date().getDate() + 1);
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
      this.stepper.previous();
    });

    this.outstationFormGroup = this.fb.group({
      triptype: ['oneway', Validators.required],
      from: ['', Validators.required],
      to: ['', Validators.required],
      date: [new Date, Validators.required],
      time: ['', Validators.required],
      returnDate: [new Date]
    });

    this.outstationFormGroup.get('time').setValue(moment(Date.now()).format('h:mm a'));

    this.contactFormGroup = this.fb.group({
      name: ['', Validators.required],
      email: [''],
      phone: ['', [
        Validators.required,
        Validators.pattern(/^[0]?[6789]\d{9}$/)
      ]]
    })

    this.mapsAPILoader.load().then(() => {
      const origin = this.originElementRef?.nativeElement;
      const destination = this.destinationElementRef?.nativeElement;

      this.addMapEventListener(origin, 'origin');
      this.addMapEventListener(destination, 'destination');

    });

  }

  get outstationValidation() { return this.outstationFormGroup.controls; }

  get contactValidation() { return this.contactFormGroup.controls; }

  dateChanged() {
  
  }

  checkDate(start, end){

    let startDate =  moment(start, 'DD-MM-YYYYTHH:mm:ss');
    let returnDate = moment(end, 'DD-MM-YYYYTHH:mm:ss');
    return startDate.isAfter(returnDate);
   }
   

  selectCars() {
    this.submitted = true;

    // if(this.outstationFormGroup.get('triptype').value === 'roundtrip') {
    //   if(!this.outstationFormGroup.get('returnDate').value) {
    //     this.showDateError = true;
    //     alert('Enter Valid Return Date');
    //     return
    //   }
    // }


    if(this.outstationFormGroup.get('triptype').value === 'roundtrip') {
      if(this.checkDate(this.outstationFormGroup.get('date').value, this.outstationFormGroup.get('returnDate').value)) {
        alert('Enter valid Return Date');
        return
      }
    }

    if (this.outstationFormGroup.invalid || this.showSpinner) {
      return;
    }

    this.estimations = [];
    this.routeDetails = {
      ...this.outstationFormGroup.value,
      distance: this.totalDistance,
      duration: this.totalDuration
    }
    this.getEstimation(this.routeDetails);
    this.stepper.next();
    this.scrollToSectionHook();
  }

  


  private scrollToSectionHook() {
    const element = document.querySelector('body');
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: 'smooth', block: 'start', inline:
            'nearest'
        });
      }, 250);
    }
  }

  selectedCar($event) {
    this.selectedCarType = $event;
    this.stepper.next();
    this.scrollToSectionHook();
    console.log(this.selectedCarType);
  }

  back() {
    this.stepper.previous();
  }

  getEstimation(routes) {
    let distance;

    if (routes.triptype === 'oneway') {
      distance = (routes.distance <= 130) ? routes.distance = 130 : routes.distance;
    } else {
      let startDate = moment(moment(routes.date).format('DD/MM/YYYY'), "DD.MM.YYYY");
      let returnDate = moment(moment(routes.returnDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
      this.days = returnDate.diff(startDate, 'days') + 1;
      // alert(startDate);
      // alert(returnDate);
      // alert(this.days);

      distance = (routes.distance * 2 <= 250) ? routes.distance = this.days * 250 : (routes.distance * 2 > 250 * this.days) ? routes.distance =  routes.distance * 2 : routes.distance = 250 * this.days;  
    }

    this.cars.forEach(car => {

      car['tripEstimation'] = (routes.triptype === 'oneway') ? car.oneway * distance + car.onewayDriverFee : car.roundtrip * distance + car.roundTripDriverFee * this.days;
      car['driverBatta'] = (routes.triptype === 'oneway') ? car.onewayDriverFee : car.roundTripDriverFee * this.days;
      car['ratePerKM'] = (routes.triptype === 'oneway') ? car.oneway : car.roundtrip;
      car['distance'] = routes.distance;
      car['baseFare'] = (routes.triptype === 'oneway') ? car.oneway * distance : car.roundtrip * distance;
      car['triptype'] = routes.triptype;
      car['pickupDate'] = moment(routes.date).format('DD/MM/YYYY');
      car['pickupTime'] = routes.time;
      car['returnDate'] = moment(routes.returnDate).format('DD/MM/YYYY');;

      this.estimations.push(car);
    });
  }


  onResponses(event) {
    this.totalDistance = event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, "");
    this.totalDuration = event.routes[0].legs[0].duration.text;
    this.showSpinner = false;
  }




  bookNow() {
    this.contactSubmitted = true;

    if (this.contactFormGroup.invalid) {
      return;
    }

    this.bookingDetails = {
      ...this.contactFormGroup.value,
      ...this.selectedCarType,
      bookingId: Math.random().toString(36).substr(2, 9).toLocaleUpperCase()
    }

    let bookingDetails = {
      'bookingId': this.bookingDetails.bookingId,
      'name': this.bookingDetails.name,
      'phoneNo': this.bookingDetails.phone,
      'email': this.bookingDetails.email,
      'origin': this.origin,
      'destination': this.destination,
      'distance': this.bookingDetails.distance,
      'carType': this.bookingDetails.carType,
      'bookedDate': this.bookingDetails.pickupDate,
      'bookedTime': this.bookingDetails.pickupTime,
      'estimate': this.bookingDetails.tripEstimation,
      'duration': this.totalDuration,
      'tripType': this.bookingDetails.triptype,
      'driverBatta': this.bookingDetails.driverBatta,
      'ratePerKM': this.bookingDetails.ratePerKM,
      'returnDate': (this.bookingDetails.triptype === 'roundtrip') ? this.bookingDetails.returnDate : 'NA',
      'adminEmail': this.settings.enquiryEmail,
      'adminPhone': this.settings.phoneNo,
      'adminWhatsapp': this.settings.whatsapp,
      'websiteAddress': this.settings.websiteAddress,
      'siteName': this.settings.siteName,
      'siteInfo': this.settings.siteInfo,
      'adminEmailSubject': `${this.settings.adminEmailSubject} ${this.bookingDetails.name}`,
      'bannerColor': this.settings.bannerColor,
      'appPassword': this.settings.appPassword,
      'appEmail': this.settings.appEmail,
      logoURL: this.settings.logoURL
    }

    let smsMessage = `Hi ${bookingDetails.name}, Thanks for booking a Taxi with ${this.settings.siteName}. Your Booking Details:\r\nBooking ID: ${bookingDetails.bookingId}\r\nName: ${bookingDetails.name}\r\nMobile Number: ${bookingDetails.phoneNo}\r\nPickup Location: ${bookingDetails.origin}\r\nDrop Location: ${bookingDetails.destination} \r\nPickup Date/Time: ${bookingDetails.bookedDate} ${bookingDetails.bookedTime} \r\nTrip Type: ${bookingDetails.tripType} \r\nTotal KM: ${bookingDetails.distance} KM\r\nTotal Trip Fare: ${bookingDetails.estimate} ₹\r\nRate Per KM: ${bookingDetails.ratePerKM} ₹\r\nDriver Batta: ${bookingDetails.driverBatta} ₹\r\nToll, parking, permit extra \r\n For any questions please contact ${this.settings.phoneNo}`

    let smsRoundTripMessage = `Hi ${bookingDetails.name}, Thanks for booking a Taxi with ${this.settings.siteName}. Your Booking Details:\r\nBooking ID: ${bookingDetails.bookingId}\r\nName: ${bookingDetails.name}\r\nMobile Number: ${bookingDetails.phoneNo}\r\nPickup Location: ${bookingDetails.origin}\r\nDrop Location: ${bookingDetails.destination} \r\nPickup Date/Time: ${bookingDetails.bookedDate} ${bookingDetails.bookedTime}\r\nRetrun Date: ${bookingDetails.returnDate} \r\nTrip Type: ${bookingDetails.tripType} \r\nTotal KM: ${bookingDetails.distance} KM\r\nTotal Trip Fare: ${bookingDetails.estimate} ₹\r\nRate Per KM: ${bookingDetails.ratePerKM} ₹\r\nDriver Batta: ${bookingDetails.driverBatta} ₹\r\nToll, parking, permit extra \r\n For any questions please contact ${this.settings.phoneNo}`



    let whatsappMessage = (bookingDetails.tripType === 'oneway') ? window.encodeURIComponent(smsMessage) : window.encodeURIComponent(smsRoundTripMessage);

    let newSMS = `Hi ${bookingDetails.name}, Thanks for booking a Taxi with ${this.settings.siteName}. Your booking is confirmed our representative will call in 10-15 mins. For any question please contact ${this.settings.phoneNo}`


    let smsBody = {
      message: newSMS,
      numbers: `${bookingDetails.phoneNo}`,
      smsKey: this.settings.smsKey
    }

    this.http.post(this.settings.emailAPI, bookingDetails).subscribe(res => console.log(res));
    this.http.post(this.settings.smsAPI, smsBody).subscribe(res => console.log(res));

    let whatsupText = `https://api.whatsapp.com/send?phone=${this.settings.whatsapp}&text=${whatsappMessage}`;
    window.open(whatsupText, "_blank");

    this.stepper.next();
    this.scrollToSectionHook();
  }


  addMapEventListener(input, type) {
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setComponentRestrictions({
      country: ["in"],
    });
    autocomplete.addListener("place_changed", () => {

      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        (type === 'origin') ? this.origin = place.formatted_address : this.destination = place.formatted_address;
        (type === 'origin') ? this.originCityName = place.name : this.destinationCityName = place.name;

        if (this.origin && this.destination) {
          this.showSpinner = true;
          this.showDirection = true;
          this.pickupPlace = this.origin;
          this.dropPlace = this.destination;
        }

        this.zoom = 12;
      });
    });
  }

}
