<section class="wo-main-section">
    <div class="container">
        <div class="row">
            <div id="wo-twocolumns" class="wo-twocolumns">
                <div class="col-lg-8">
                    <div class="wo-vsinglehead">
                        <div class="wo-vsinglehead__title">
                            <h1><span>{{origin}}</span> to <span>{{destination}} Outstation Taxi</span></h1>
                        </div>
                    </div>
                    <div id="wo-vsingleslider" class="wo-vsingleslider owl-carousel owl-loaded owl-drag">
                        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="5">
                            <ng-container *ngIf="showDirection">
                                <agm-direction [origin]="origin" [destination]="destination"
                                    (onResponse)="onResponses($event)">
                                </agm-direction>
                            </ng-container>
                        </agm-map>

                    </div>
                    <ul *ngIf="estimations.length" class="wo-vslider-features">
                        <li>
                            <img src="assets/car.png" alt="img description">
                            <h4>{{estimations[0].carType}}</h4>
                            <span>Car Type</span>
                        </li>
                        <li>
                            <img src="assets/distance.png" alt="img description">
                            <h4>{{estimations[0].oneWayDistance}} KM</h4>
                            <span>Total Distance</span>
                        </li>
                        <li>
                            <img src="assets/time.png" alt="img description">
                            <h4>{{estimations[0].totalDuration}}</h4>
                            <span>Total Duration</span>
                        </li>
                        <li>
                            <img src="assets/money.png" alt="img description">
                            <h4>{{estimations[0].oneway}} ₹</h4>
                            <span>Rate Per KM</span>
                        </li>
                        <li>
                            <img src="assets/van.png" alt="img description">
                            <h4>{{estimations[0].oneWaytripEstimation | currency: "INR"}}</h4>
                            <span>Trip Cost</span>
                        </li>
                        <div class="wo-priceinfo__contact">
                            <a class="wo-contectnum"><i class="ti-mobile"></i>Click To
                                Call<span>{{settings.phoneNo}}</span></a>
                            <a class="wo-contectnum wo-contectmsg"><i
                                    class="fab fa-whatsapp"></i>Chat with Us<span> {{settings.whatsapp}} </span></a>
                        </div>
                    </ul>

                    <div class="wo-vsingledetails">
                        <div class="wo-vsingledetails__title">
                            <h3>Book {{origin}} to {{destination}} Cabs</h3>
                        </div>
                        <div class="wo-vsingledetails__content">
                            <div class="wo-description">
                                <p>{{settings.siteName}}, the Brand of Cab Service is known for Outstation Cab services
                                    in India
                                    and one way cabs in India. {{settings.siteName}} is specialized in Drop Taxi
                                    Services. We
                                    provide outstation taxi services from multiple cities and states of India.

                                </p>
                                <p>Booking a one way cab from {{origin}} to {{destination}} is easy and convenient with
                                    {{settings.siteName}}. We provide both Round trip cabs and one way cabs. The drivers
                                    on this route are well professional, courteous and they drive frequently in these
                                    routes. If
                                    you're planning to visit {{destination}} for one or two days, you can opt for an
                                    Outstation Round trip. If you are going to stay there for more days, booking a cab
                                    from {{origin}} to {{destination}} one way drop would be ideal.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="wo-vsingledetails">
                        <div class="wo-vsingledetails__title">
                            <h3>{{origin}} to {{destination}} Taxi Fare</h3>
                        </div>
                        <div class="wo-vsingledetails__content">
                            <div class="wo-description">
                                <p>
                                    Get the cheapest price in {{origin}} to {{destination}} cabs on
                                    {{settings.siteName}}. Book from a range of
                                    rental car choices in {{origin}} - Sedans, SUVs, or tempo travelers.
                                    {{settings.siteName}} recommends
                                    Sedan if you are trying to find a compact ride for a solo trip or a visit along with
                                    your lifeline. If you are traveling with a little family, you will be able to select
                                    the additional spacious sedan cars.
                                </p>
                                <p>
                                    However, if you are traveling with a group of people, select an SUV or luxury tempo
                                    traveler in {{origin}}, the one that suits your demand. Get the best discounts and
                                    cashback on the rental car choices, from {{origin}} to {{destination}} prices start
                                    from the
                                    lowest. For more details, Submit your travel request now, and get multiple quotes
                                    from different operators in one place.
                                </p>
                            </div>

                            <div class="wo-vcomlistwrap">

                                <ul class="wo-vcomlist">
                                    <li *ngFor="let car of estimations">
                                        <span>
                                            <img src="{{car.image}}" alt="" srcset=""> <br>
                                            {{car.carType}} <br>
                                            <em>({{car.passengers}} + 1)</em>
                                        </span>
                                        <span>
                                            One Way <br>
                                            <b> {{car.oneWaytripEstimation}} ₹ </b> <br>
                                            <em>({{car.oneway}} ₹ per km)</em>
                                        </span>
                                        <span>
                                            Round Trip <br>
                                            <b> {{car.roundTripEstimation}} ₹ </b><br>
                                            <em>({{car.roundtrip}} ₹ per km)</em>
                                        </span>
                                        <div class="wo-header-btns-list">
                                            <a href="tel:{{settings.phoneNo}}" class="wo-btn"><i
                                                    class="ti-mobile"></i>Click to call</a>
                                            <a routerLink="/" class="wo-btn wp">
                                                Book Now</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div class="wo-vsingledetails">
                        <div class="wo-vsingledetails__title">
                            <h3>How to Book a {{origin}} to {{destination}} Cabs?
                            </h3>
                        </div>
                        <div class="wo-vsingledetails__content">
                            <div class="wo-description">
                                <p>It is relatively easy to book a {{origin}} to {{destination}} Cabs through online
                                    booking on
                                    our {{settings.siteName}} website.

                                </p>
                                <p>Follow these simple steps to book a {{origin}} to {{destination}} Cabs on
                                    {{settings.siteName}}:
                                </p>
                            </div>
                            <ul class="wo-adfeatureslist">
                                <li>
                                    <span>Step 1: Visit {{settings.siteName}}
                                    </span>
                                </li>
                                <li>
                                    <span>Step 2: Enter your departure station as {{origin}} and arrival as your
                                        destination along with the date of travel. You get to see {{origin}} to
                                        {{destination}}
                                        taxi prices for all types of vehicles.
                                    </span>
                                </li>
                                <li>
                                    <span>Step 3: Select the cheapest {{origin}} to {{destination}} Cab operators of
                                        your choice
                                        and proceed.
                                    </span>
                                </li>
                                <li>
                                    <span>You will receive the confirmation on your registered mobile number and e-mail
                                        ID after successful payment.

                                    </span>
                                </li>
                            </ul>
                            <p>
                                With our vast range of services, you can book a {{origin}} to {{destination}} Cab with
                                simple
                                clicks. Avail {{origin}} to {{destination}} Cab services at {{settings.siteName}} and
                                enjoy a great trip with
                                your family and friends.
                            </p>
                        </div>
                    </div>
                    <div class="wo-vsingledetails wo-mhistory">
                        <div class="wo-vsingledetails__title">
                            <h3>Why Book a {{origin}} to {{destination}} Cab with {{settings.siteName}}?
                            </h3>
                        </div>
                        <ul class="wo-adfeatureslist">
                            <li>
                                <span>With our quick and affordable {{origin}} to {{destination}} cab services, be
                                    assured of
                                    on-time pick-up and drop whereas enjoying a smooth ride along with your favorite
                                    ones in our clean and sanitized cars.

                                </span>
                            </li>
                            <li>
                                <span>You won't have to be compelled to worry regarding the hidden charges any longer -
                                    You pay what you see.
                                </span>
                            </li>
                            <li>
                                <span>Our polite and professional chauffeurs are your good guide and companion on the
                                    roads.
                                </span>
                            </li>
                            <li>
                                <span>Not sure regarding your plan? No upsets! We have got all been there. Hence, we
                                    tend to charge zero cancellation fees.
                                </span>
                            </li>
                        </ul>

                        <div class="wo-vsingledetails__content">
                            <div class="wo-description">
                                <p>
                                    Hire taxi from {{origin}} to {{destination}} by making an online booking on our
                                    website.
                                    {{settings.siteName}} provides Airport Taxi Services, Call Taxi services and Rental
                                    cabs. You
                                    get cheapest One way fares with {{settings.siteName}}. We don't collect any night
                                    driving
                                    charges and hidden charges which makes {{settings.siteName}} the best and reliable
                                    car rentals
                                    from {{origin}} to {{destination}}. We provide regular One way taxi services from
                                    {{origin}} Airport to {{destination}} drop or {{origin}} to {{destination}} Airport
                                    drop (if
                                    airport is available on this route) or Railway station, city pickup and drop at best
                                    price.
                                </p>
                                <p>
                                    You can easily book a {{origin}} to {{destination}} Cab with {{settings.siteName}}.
                                    {{origin}} to
                                    {{destination}} Taxi fare is very cheap and affordable. If you want to get dropped
                                    at
                                    {{destination}} city, railway station, airport or anywhere in {{destination}}, book
                                    a one
                                    way cab from {{origin}} to {{destination}} drop with {{settings.siteName}}.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="wo-sinstruction">
                        <div class="wo-sinstruction__content">
                            <h4>Safty Instruction: Covid-19 Safety precautions</h4>
                            <p>The health and safety of our passengers, drivers, and employees is our top priority.
                                Hence we have taken stringent safety measures consistent with the safety guidelines by
                                the Indian Govt. {{settings.siteName}} S.A.F.E rides:
                            </p>
                            <ul>
                                <li><span>S - Sanitisers in cab
                                </span></li>
                                <li><span>A - Aarogya Setu App for drivers
                                </span></li>
                                <li><span>F - Face Mask mandated
                                </span></li>
                                <li><span>E - Ensuring disinfected cabs

                                </span></li>

                            </ul>

                            <br>

                            <p>To find out more about the safety measures taken by us, visit our COVID-19 safety page.

                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>