<mat-horizontal-stepper linear #stepper>
  <mat-step>

    <form [formGroup]="outstationFormGroup" (ngSubmit)="selectCars()"
      class="wo-themeform wo-bannerform">
      <fieldset>
        <label class="wo-titleinput one">I’m Looking For:</label>

        <div class="wo-radioholder">
          <span class="wo-radio">
            <input type="radio" id="Buy" value="oneway" name="triptype" checked=""
              formControlName="triptype">
            <label for="Buy">
              <span><i class="far fa-circle"></i>One Way</span>
            </label>
          </span>
          <span class="wo-radio">
            <input type="radio" id="Rent" name="triptype" value="roundtrip" formControlName="triptype">
            <label for="Rent">
              <span><i class="far fa-circle"></i>Round Trip</span>
            </label>
          </span>
        </div>
        <div class="form-group">
          <label class="wo-titleinput">From</label>
          <div class="wo-form-icon">
            <input type="text" class="form-control" placeholder="Start typing city - e.g. Chennai"
              required="" formControlName="from" #origin>

            <a class="wo-right-icon"><i class="ti-target"></i></a>
          </div>
          <div *ngIf="submitted && outstationValidation.from.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="outstationValidation.from?.errors.required">Origin is required</div>
          </div>
        </div>
        <div class="form-group">
          <label class="wo-titleinput">To</label>
          <div class="wo-form-icon">
            <input type="text" class="form-control" placeholder="Start typing city - e.g. Bangalore"
              required="" formControlName="to" #destination>

            <a class="wo-right-icon"><i class="ti-target"></i></a>
          </div>
          <div *ngIf="submitted && outstationValidation.to.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="outstationValidation.to?.errors.required">Destination is required</div>
          </div>
        </div>

        <div class="date-wrap">
          <div class="form-group">
            <label class="wo-titleinput">Pickup Date</label>
            <div class="wo-form-icon">
              <input [min]="minDate" type="text" class="form-control" placeholder="Select Date" readonly
                formControlName="date" (focus)="picker.open()" [matDatepicker]="picker" (dateChange)="dateChanged()">

              <mat-datepicker #picker></mat-datepicker>

              <a class="wo-right-icon"><i class="ti-calendar"></i></a>
            </div>
            <div *ngIf="submitted && outstationValidation.date.errors" class="invalid-feedback"
              style="display: block;">
              <div *ngIf="outstationValidation.date?.errors.required">Pickup date is required</div>
            </div>
          </div>
          <div class="form-group">
            <label class="wo-titleinput">Pickup Time</label>
            <div class="wo-form-icon">
              <input type="text" class="form-control" placeholder="Select Time" required=""
                formControlName="time" [ngxTimepicker]="time">
              <ngx-material-timepicker #time></ngx-material-timepicker>

              <a class="wo-right-icon"><i class="ti-timer"></i></a>
            </div>
            <div *ngIf="submitted && outstationValidation.time.errors" class="invalid-feedback"
              style="display: block;">
              <div *ngIf="outstationValidation.time?.errors.required">Pickup time is required</div>
            </div>
          </div>
        </div>

        <div *ngIf="outstationFormGroup.get('triptype').value === 'roundtrip'" class="form-group">
          <label class="wo-titleinput">Return Date</label>
          <div class="wo-form-icon">
            <input [min]="outstationValidation.date.value" type="text" class="form-control" placeholder="Select Date" readonly
              formControlName="returnDate" (focus)="picker.open()" [matDatepicker]="picker">
            <mat-datepicker #picker></mat-datepicker>

            <a class="wo-right-icon"><i class="ti-calendar"></i></a>
          </div>

          <div *ngIf="showDateError" class="invalid-feedback"
          style="display: block;">
          <div>Enter Valid Retrun Date</div>
        </div>

         

          
          
        </div>


        <div class="form-group wo-form-btns">
          <!-- [disabled]="!outstationFormGroup.valid" -->
          <button type="submit" class="wo-btn">
            <mat-spinner *ngIf="showSpinner" [diameter]="40" [color]="123652"></mat-spinner>
            <span *ngIf="!showSpinner">Get Set Go</span>
          </button>
        </div>

        <section class="goSafeHomeWrap fl width100">
          <h3 class="goSafeCardHeader white">Safe Travel</h3>
          <ul class="goSafeCardScroller">
            <li class="goSafeCardWrap">
              <h3 class="goSafeCardHeader">Introducing Safe Cars</h3>
              <ul class="goSafeCardList">
                <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Sanitized
                    environment</span></li>
                <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Trained staff</span>
                </li>
                <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Safe practices</span>
                </li>
              </ul>
              <div class="cardImagePos"><img src="https://gos3.ibcdn.com/go_safe_bus-1589817050.png" alt=""
                  style="width: 62px;"></div>
            </li>
            <li class="goSafeCardWrap">
              <h3 class="goSafeCardHeader">Sanitized environment</h3>
              <ul class="goSafeCardList">
                <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Deep Cleaned
                    Cars</span></li>

                <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Hand sanitisers
                    provided</span></li>
              </ul>
              <div class="cardImagePos"><img src="https://gos3.ibcdn.com/sannitize_env-1590165711.png" alt=""
                  style="width: 62px;"></div>
            </li>
            <li class="goSafeCardWrap">
              <h3 class="goSafeCardHeader">Trained staff</h3>
              <ul class="goSafeCardList">
                <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Driver
                    with masks</span></li>
                <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Regular temperature
                    checks</span></li>
              </ul>
              <div class="cardImagePos"><img src="https://gos3.ibcdn.com/logo_train_staff-1590215654.png" alt=""
                  style="width: 62px;"></div>
            </li>
            <li class="goSafeCardWrap">
              <h3 class="goSafeCardHeader">Safe Practices</h3>
              <ul class="goSafeCardList">
                <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Use hand
                    sanitizer</span></li>
                <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Wear face mask all
                    time</span></li>
                <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Contact
                    Tracing</span></li>
              </ul>
              <div class="cardImagePos"><img src="https://gos3.ibcdn.com/logo_safe_practice-1590215777.png" alt=""
                  style="width: 62px;"></div>
            </li>
          </ul>
        </section>
      </fieldset>
    </form>

    <div class="hide-map">
      <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="5">
        <ng-container *ngIf="showDirection">
          <agm-direction [origin]="pickupPlace" [destination]="dropPlace"
            (onResponse)="onResponses($event)">
          </agm-direction>
        </ng-container>
      </agm-map>
    </div>

  </mat-step>
  <mat-step>
    <a (click)="back()"> <i class="fa fa-long-arrow-left fa-3" aria-hidden="true"></i>
      back
    </a>

    <div class="wo-tabtitle">
      <h4>Select your preffered vehicle</h4>
    </div>
    <div class="wo-vcomlistwrap">

      <ul class="wo-vcomlist">

        <ng-container *ngFor="let car of estimations">
          <li *ngIf="car.carType !=='Tempo Traveller' && car.triptype === 'oneway' || car.triptype === 'roundtrip'">
            <span>
              <img src="{{car.image}}" alt="" srcset=""> <br>
              {{car.carType}} <br>
              <em>({{car.passengers}} + 1)</em>
            </span>
            <span>
              {{car.ratePerKM}} ₹ / KM <br>
              <em>(Rate per km)</em>

            </span>
            <span class="inr">
              <em *ngIf="days"> For {{days}} Days </em>
              {{car.tripEstimation |currency:'INR'}} <br>
              <em>({{ routeDetails.triptype !== 'oneway' ? car.distance : car.distance }} km)</em>
            </span>
            <div class="wo-header-btns-list">
              <a href="tel:{{settings.phoneNo}}" class="wo-btn"><i class="ti-mobile"></i>Click to call</a>
              <a (click)="selectedCar(car)" class="wo-btn wp">
                Select</a>
            </div>
          </li>
        </ng-container>

      </ul>
    </div>

  </mat-step>
  <mat-step>
    <a (click)="back()"> <i class="fa fa-long-arrow-left fa-3" aria-hidden="true"></i>
      back
    </a>
    <div class="wo-tabtitle">
      <h4>Enter contact details</h4>
    </div>
    <form [formGroup]="contactFormGroup" (ngSubmit)="bookNow()" class="wo-themeform wo-bannerform">
      <fieldset>

        <div class="form-group">
          <label class="wo-titleinput">Name</label>
          <div class="wo-form-icon">
            <input type="text" class="form-control" placeholder="Enter your name" required=""
              formControlName="name">
          </div>
          <div *ngIf="contactSubmitted && contactValidation.name.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="contactValidation.name?.errors.required">Name is required</div>
          </div>
        </div>
        <div class="form-group">
          <label class="wo-titleinput">Email (optional)</label>
          <div class="wo-form-icon">
            <input type="email" class="form-control" placeholder="Enter e-mail address" 
              formControlName="email">
          </div>
          <div *ngIf="contactSubmitted && contactValidation.email.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="contactValidation.email?.errors.required">Email is required</div>
            <div *ngIf="contactValidation.email?.errors.pattern">Enter valid email address</div>
          </div>
        </div>

        <div class="form-group">
          <label class="wo-titleinput">Mobile Number</label>
          <div class="wo-form-icon">
            <input type="text" class="form-control" placeholder="Enter contact number" required=""
              formControlName="phone">
          </div>
          <div *ngIf="contactSubmitted && contactValidation.phone.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="contactValidation.phone?.errors.required">Mobile number is required</div>
            <div *ngIf="contactValidation.phone?.errors.pattern">Enter valid mobile number</div>
          </div>
        </div>

        <div class="form-group wo-form-btns">
          <button type="submit" class="wo-btn">Book your ride</button>
        </div>
      </fieldset>
    </form>

    <mat-tab-group>
      <mat-tab label="Details">
        <div *ngIf="selectedCarType" class="col-12">
          <div class="x_car_book_left_siderbar_wrapper float_left">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <!-- Filter Results -->
                <div class="car-filter accordion x_inner_car_acc_accor">
                  <!-- Resources -->
                  <div class="x_car_access_filer_top_img">
                    <img src="{{selectedCarType.image}}" alt="car_img">
                    <h3>{{selectedCarType.carType}}</h3>
                    <p> Total distance {{ selectedCarType.distance }} KM</p>
                  </div>
                  <hr>
                  <!-- Company -->
                  <div class="panel panel-default x_car_inner_acc_acordion_padding">
                    <div class="panel-heading">
                      <h5 class="panel-title"> <a data-toggle="collapse" href="#collapseTwo" class="collapse">
                          date</a> </h5>
                    </div>
                    <div id="collapseTwo" class="collapse show">
                      <div class="panel-body">
                        <div class="x_car_acc_filter_date">
                          <ul>
                            <li>From <span> {{selectedCarType.pickupDate}}</span>
                            </li>
                            <li>Time <span>{{selectedCarType.pickupTime}}</span>
                            </li>
                            <li>Duration <span>{{totalDuration}}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <!-- Attributes -->
                  <div class="panel panel-default x_car_inner_acc_acordion_padding">
                    <div class="panel-heading">
                      <h5 class="panel-title"> <a data-toggle="collapse" href="#collapseThree" class="collapse">
                          location</a> </h5>
                    </div>
                    <div id="collapseThree" class="collapse show">
                      <div class="panel-body">
                        <div class="x_car_acc_filter_date">
                          <ul>
                            <li>Pick-up <span>{{originCityName | limit : 20}}</span>
                            </li>
                            <li>Drop <span>{{destinationCityName | limit : 20}}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div
                    class="panel panel-default x_car_inner_acc_acordion_padding x_car_inner_acc_acordion_padding_last">
                    <div class="panel-heading">
                      <h5 class="panel-title"> <a data-toggle="collapse" href="#collapsefour" class="collapse">
                          Fare Breakup</a> </h5>
                    </div>
                    <div id="collapsefour" class="collapse show">
                      <div class="panel-body">
                        <div class="x_car_acc_filter_date">
                          <ul>
                            <li>Base Fare <span>{{selectedCarType.baseFare}} ₹
                              </span>
                            </li>
                            <!-- <li>Driver Batta <span>{{selectedCarType.driverBatta}} ₹ <em *ngIf="routeDetails.triptype !== 'oneway'"> (For {{this.days}} days)</em> </span>
                            </li> -->
                            <li>Rate per KM <span>{{selectedCarType.ratePerKM}} ₹</span>
                            </li>


                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="x_car_acc_filter_bottom_total">
                    <ul>
                      <li>total <span>{{selectedCarType.tripEstimation | currency: 'INR'}}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <!-- <mat-tab label="Inclusions">
        <ul *ngIf="selectedCarType" class="wo-vehicles__list">
          <li><span><i class="wo-themeicon"><img src="assets/money.png" alt="img description"></i>Base
              Fare for {{selectedCarType.distance}} km </span></li>
          <li><span><i class="wo-themeicon"><img src="assets/driver.png"
                  alt="img description"></i>Driver Allowance of {{selectedCarType.driverBatta |
              currency: 'INR'}}</span></li>
            
        </ul>
      </mat-tab> -->
      <!-- <mat-tab label="Exclusions">
        <ul *ngIf="selectedCarType" class="wo-vehicles__list">
          <li><span><i class="wo-themeicon"><img src="assets/images/index/featured/img-02.png"
                  alt="img description"></i>Pay {{selectedCarType.ratePerKM}} ₹ /km
              after {{selectedCarType.distance}} km</span></li>
              <li><span><i class="wo-themeicon"><img src="assets/driver.png"
                alt="img description"></i>Driver Allowance of for Sedan Above 400 Km is ₹500</span></li>
                <li><span><i class="wo-themeicon"><img src="assets/driver.png"
                  alt="img description"></i>Driver Allowance of for SUV Above 400 Km is ₹700</span></li>
            
       
          <li><span><i class="wo-themeicon"><img src="assets/taxi.png"
                  alt="img description"></i>Multiple pickups/drops</span></li>

                  <li><span><i class="wo-themeicon"><img src="assets/taxes.png" alt="img description"></i>State
                    Tax & Toll </span></li>
          <li><span><i class="wo-themeicon"><img src="assets/toll.png" alt="img description"></i>Parking
              / Toll</span></li>
              <li><span><i class="wo-themeicon"><img src="assets/taxi.png"
                alt="img description"></i>Hill Charges Extra</span></li>
            

        </ul>
      </mat-tab> -->
    </mat-tab-group>

  </mat-step>

  <mat-step>
    <div *ngIf="bookingDetails" class="confirmationText">

      <div class="wo-sectionhead__title">
        <h2>Thank You, <em>{{bookingDetails.name}}</em> !</h2>
      </div>

      <p class="bookingId">Your Booking (ID: <span
          class="reservation-id">{{bookingDetails.bookingId}}</span>) is
        confirmed.</p>

      <p class="bookingMessage">The reservation details have been sent to your email
        ID and phone. You will receive your driver details
        2 hours prior to your pick up time. </p>

      <p class="bookingId" style="margin-top: 10px;">In these unprecedented times,
        {{settings.siteName}} is pleased to provide a comprehensive yet cost-effective rides. For
        details please contact <a href="tel:{{settings.phoneNo}}"> {{settings.phoneNo}} </a> </p>
    </div>
  </mat-step>
</mat-horizontal-stepper>