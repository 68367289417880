export const SITE_SETTINGS = {
    siteName: 'Kaviya Drop Taxi',
    phoneNo: '7418033380',
    whatsapp: '+917418033380',
    websiteAddress: 'www.kaviyadroptaxi.in',
    smsKey: 'VScK10E5fYbBJ74w3zM6UmFCHklnd9gXW2ItraeohpsjGNZiRAtcKxaZMWz46b1IwSEC9RmYQfu7dXrp',
    enquiryEmail: 'kaviyadroptaxe@gmail.com',
    emailAPI: 'https://us-central1-calvincare-emailservice.cloudfunctions.net/sendEmail',
    smsAPI: 'https://us-central1-smsapi-service.cloudfunctions.net/sendSms/api/v1/sendsms',
    quickEnquiry: 'https://us-central1-smsapi-service.cloudfunctions.net/quickEnquiry',
    telegramAPI: 'https://us-central1-telegram-api-service.cloudfunctions.net/telegramAPI/api/v1/telegram',
    siteInfo: `Toll Gate, Permit, Hill Station Charges Extra`,
    adminEmailSubject: 'Website Enquiry Email From',
    bannerColor: "#fdc500",
    appPassword: 'oqhjzmcdwbuzylwh',
    appEmail: 'kaviyadroptaxe@gmail.com',
    logoURL: 'https://www.kaviyadroptaxi.in/assets/logo.jpg',
}